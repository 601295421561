import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { takeUntil, tap } from "rxjs/operators"
import { AddOnViewService } from "../../services/add-on-view.service"
import { Observable, Subject } from "rxjs"

@Component({
    templateUrl: "./log-in-add-on.component.html",
    styleUrls: ["./log-in-add-on.component.scss"],
})
export class LogInAddOnComponent implements OnInit, OnDestroy {
    selectedAddOn: string = ""
    private destroyed$: Subject<void> = new Subject<void>()

    addOnObservable: Observable<string> = this.addOnService.selectedAddOnObservable().pipe(
        takeUntil(this.destroyed$),
        tap((selectedAddon: string) => (this.selectedAddOn = selectedAddon))
    )

    routerObservable: Observable<any> = this.route.data.pipe(
        takeUntil(this.destroyed$),
        tap((data: { addOn?: string }) => (this.addOnService.selectedAddOn = data?.addOn))
    )
    constructor(private route: ActivatedRoute, private addOnService: AddOnViewService) {}

    ngOnInit(): void {
        this.routerObservable.subscribe()
        this.addOnObservable.subscribe()
    }

    ngOnDestroy() {
        this.destroyed$.next()
        this.destroyed$.complete()
    }
}
