import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { IframeGuard } from "../guards/Iframe.guard"
import { TeacherAuthGuard } from "../guards/TeacherAuth.guard"
import { TeacherExperienceOnlyGuard } from "../guards/TeacherExperienceOnly.guard"
import { LogInAddOnComponent } from "./log-in-iframe/log-in-add-on.component"

const addOnRoutes: Routes = [
    { path: "", redirectTo: "add-on/log-in", pathMatch: "prefix" },
    {
        path: "add-on",
        children: [
            {
                path: "log-in",
                component: LogInAddOnComponent,
            },
            {
                path: "search",
                loadChildren: () =>
                    import("./../search-page/search-page.module").then((m) => m.SearchPageModule),
                canActivate: [IframeGuard],
            },
            {
                path: "subjects",
                loadChildren: () =>
                    import("./../browsing/browsing.module").then((m) => m.BrowsingModule),
                canActivate: [IframeGuard],
            },
            {
                path: "grades",
                loadChildren: () =>
                    import("./../browse-by-grade/browse-by-grade.module").then(
                        (m) => m.BrowseByGradeModule
                    ),
                canActivate: [IframeGuard],
            },

            {
                path: "profile",
                loadChildren: () =>
                    import("./../user-profile/user-profile.module").then(
                        (m) => m.UserProfileModule
                    ),
                canActivate: [TeacherAuthGuard, TeacherExperienceOnlyGuard, IframeGuard],
            },

            {
                path: "resource",
                loadChildren: () =>
                    import("./../resource/resource.module").then((m) => m.ResourceModule),
                data: { shouldReuse: false },
                canActivate: [IframeGuard],
            },
        ],
    },
]

@NgModule({
    imports: [RouterModule.forChild(addOnRoutes)],
    exports: [RouterModule],
})
export class AddOnRoutingModule {}
