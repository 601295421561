<footer class="container-wrapper selenium-app-footer w-100" id="footer">
    <!-------------- Student experience ------------>
    <ng-container *ngIf="isStudentExperience; else teacherExperience">
        <div
            class="d-flex flex-xs-column flex-md-row justify-xs-content-center student-container align-items-md-end"
            id="student-experience"
        >
            <div
                class="right-side ps-md-4 d-flex flex-xs-column align-items-xs-center align-items-md-start"
            >
                <div class="student-logos-and-partners ps-3">
                    <ng-container *ngTemplateOutlet="partnersDetails"></ng-container>
                </div>
                <div class="d-flex flex-xs-row align-items-xs-center mb-5 mt-2" id="all-rights">
                    <ng-container *ngTemplateOutlet="allRightsReserved"></ng-container>
                </div>
            </div>
            <div class="left-side mb-xs-8">
                <div
                    [ngClass]="{ 'logged-in-user': userService.isLoggedIn() }"
                    class="student-right-side justify-content-end d-flex flex-column justify-content-end align-middle align-items-center flex-nowrap"
                >
                    <button
                        (click)="teacherLogin()"
                        *ngIf="!userService.isLoggedIn() && !isAddonView"
                        class="btn btn-primary mb-6 fw-bold"
                    >
                        Teachers, sign in for resources
                    </button>
                    <button
                        (click)="changeUserExperience(false, '_self')"
                        *ngIf="userService.isLoggedIn() && !userService.isStudent() && !isAddonView"
                        class="btn btn-primary mb-6 fw-bold"
                    >
                        Return to Teacher view
                    </button>
                    <div class="terms-and-privacy-policy">
                        <app-link
                            [appRouterLink]="isAddonView ? null : '/about/'"
                            [attr.data-gtmlabel]="'About'"
                            [href]="isAddonView ? '/about/' : null"
                            [target]="'_blank'"
                            class="{{ gtmPrefix + 'about' }} my-2 mx-3"
                        >
                            About
                        </app-link>
                        <app-link
                            [appRouterLink]="isAddonView ? null : '/help/privacy-policy/'"
                            [attr.data-gtmlabel]="'Help'"
                            [href]="isAddonView ? '/help/privacy-policy/' : null"
                            [target]="'_blank'"
                            class="{{ gtmPrefix + 'help' }} my-2 mx-3"
                        >
                            Privacy Policy
                        </app-link>
                        <app-link
                            [appRouterLink]="isAddonView ? null : '/help/terms-of-use/'"
                            [attr.data-gtmlabel]="'Help'"
                            [href]="isAddonView ? '/help/terms-of-use/' : null"
                            [target]="'_blank'"
                            class="{{ gtmPrefix + 'help' }} my-2 mx-3"
                        >
                            Terms of Use
                        </app-link>
                        <app-link
                            [appRouterLink]="isAddonView ? null : '/help/'"
                            [attr.data-gtmlabel]="'Help'"
                            [href]="isAddonView ? '/help/' : null"
                            [target]="'_blank'"
                            class="{{ gtmPrefix + 'help' }} my-2 mx-3"
                        >
                            Help
                        </app-link>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-------------- Teacher experience ------------>
    <ng-template #teacherExperience>
        <div
            class="py-8 d-flex flex-column align-items-xs-center teacher-container align-items-sm-start flex-sm-row align-items-lg-start"
            id="teacher-experience"
        >
            <div
                class="left-side col-sm-5 ps-sm-8 ps-md-10 align-items-xs-center align-items-sm-start ps-lg-0 col-lg-4 col-xxl-3 d-flex flex-colum"
            >
                <div class="student-experience-button">
                    <button
                        (click)="changeUserExperience(true, '_blank')"
                        aria-label="Access student site"
                        class="{{ gtmPrefix + 'student-button ' + 'btn' }}
                    d-flex align-items-center fw-bold text-white student-site-button"
                        rel="noopener noreferrer"
                    >
                        Student site
                        <em class="fas fa-external-link-alt ms-2"></em>
                    </button>
                </div>
                <div class="teacher-logos-and-partners ps-lg-0">
                    <ng-container *ngTemplateOutlet="partnersDetails"></ng-container>
                </div>
            </div>
            <div class="right-side d-flex flex-xs-column flex-lg-row col-md-9">
                <div class="connect-with-us col-md-9 col-lg-6 px-xs-4">
                    <div class="social-media__inner py-0 px-0 mx-auto mb-4">
                        <h3 class="footer-title text-uppercase">
                            <a href="#" rel="noreferrer noopener">Connect With Us</a>
                        </h3>
                        <p class="sign-up-for mx-0 mb-3">
                            Sign up for our newsletter for the latest resources, news, and more.
                        </p>
                        <div class="input-group mb-6">
                            <input
                                (keyup)="validateEmail()"
                                [ngClass]="{ 'invalid-email': !isEmailValid && email.length }"
                                class="form-control input-email rounded-0 text-white"
                                id="email-iterable-newsletter"
                                placeholder="Your email"
                                required
                                title="Please enter a valid email address."
                                type="email"
                            />
                            <span class="input-group-btn input-group-prepend fw-bold">
                                <span
                                    (click)="subscribe()"
                                    [attr.data-gtmlabel]="'Go'"
                                    [ngClass]="{ 'email-disabled-button': !isEmailValid }"
                                    class="{{
                                        gtmPrefix +
                                            'newsletter-go ' +
                                            'input-group-text btn btn-success text-white button-spacing'
                                    }}
                                 fw-bold"
                                    id="iterable-newsletter-go"
                                >
                                    Sign up!
                                </span>
                            </span>
                        </div>
                    </div>
                    <span class="footer-social-links d-flex pb-xs-4">
                        <a
                            class="social-media__link text-decoration-none d-inline-block"
                            href="https://www.facebook.com/pbsteachers"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="Facebook"
                        >
                            <i class="fab fa-facebook-f ps-1 mx-0 my-0 text-white"></i>
                        </a>
                        <a
                            class="social-media__link text-decoration-none d-inline-block ms-md-9 ms-xs-7"
                            href="https://twitter.com/pbsteachers"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="Twitter"
                        >
                            <i class="fab fa-twitter mx-0 my-0 text-white"></i>
                        </a>
                        <a
                            class="social-media__link text-decoration-none d-inline-block ms-md-9 ms-xs-7"
                            href="https://www.pinterest.com/pbslm/"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="Pinterest"
                        >
                            <i class="fab pbslm-pinterest-simple mx-0 my-0 text-white"></i>
                        </a>
                        <a
                            class="social-media__link text-decoration-none d-inline-block ms-md-9 ms-xs-7"
                            href="https://www.instagram.com/pbs.teachers"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="Instagram"
                        >
                            <i class="fab fa-instagram mx-0 my-0 text-white"></i>
                        </a>
                    </span>
                </div>
                <div class="learn-more col-md-8 col-lg-4 mt-sm-2 mt-lg-0 ps-lg-6">
                    <h3 class="footer-title text-uppercase">Learn More</h3>
                    <ul class="list-unstyled py-0 px-0">
                        <li class="secondary-nav__item px-0 py-0 border-0 mb-1">
                            <app-link
                                [appRouterLink]="isAddonView ? null : '/about/'"
                                [attr.data-gtmlabel]="'About'"
                                [href]="isAddonView ? '/about/' : null"
                                [target]="'_blank'"
                                class="{{ gtmPrefix + 'help' }}"
                            >
                                About
                            </app-link>
                        </li>
                        <li class="secondary-nav__item px-0 py-0 border-0 mb-1">
                            <a
                                [attr.data-gtmlabel]="'PBS Education'"
                                class="{{ gtmPrefix + 'pbs-education' }} text-white"
                                href="https://www.pbs.org/education/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Teachers’ Lounge Blog
                            </a>
                        </li>
                        <li class="secondary-nav__item px-0 py-0 border-0 mb-1">
                            <a
                                [attr.data-gtmlabel]="'PBS Education'"
                                class="{{ gtmPrefix + 'pbs-education' }} text-white"
                                href="https://www.pbs.org/education/educator-recognition/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Educator Recognition
                            </a>
                        </li>
                        <li class="secondary-nav__item px-0 py-0 border-0 mb-1">
                            <a
                                (click)="openFeedbackForm($event)"
                                class="my-0 text-white"
                                href="#"
                                role="button"
                            >
                                Contact Us
                            </a>
                        </li>
                        <li class="secondary-nav__item px-0 py-0 border-0 mb-1">
                            <a
                                (click)="goToNewsletterPage()"
                                class="my-0 text-white newsletter-link"
                            >
                                Newsletter
                            </a>
                        </li>
                        <li class="secondary-nav__item px-0 py-0 border-0 mb-1">
                            <a
                                [attr.data-gtmlabel]="'Help'"
                                [target]="isAddonView ? '_blank' : '_self'"
                                class="{{ gtmPrefix + 'help' }} text-white"
                                href="/help/"
                            >
                                Help
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-template>

    <div *ngIf="!isStudentExperience" class="copyright-wrapper">
        <div class="legal-and-contact py-4 px-0 text-center w-100">
            <div class="col-12 d-md-flex justify-content-center">
                <ng-template [ngTemplateOutlet]="allRightsReserved"></ng-template>
                <div class="terms-and-privacy-policy d-inline-block">
                    <app-link
                        [appRouterLink]="isAddonView ? null : '/help/privacy-policy/'"
                        [attr.data-gtmlabel]="'Help'"
                        [href]="isAddonView ? '/help/privacy-policy/' : null"
                        [target]="'_blank'"
                        class="{{ gtmPrefix + 'help' }} my-2 mx-3"
                    >
                        Privacy Policy
                    </app-link>
                    <span>|</span>
                    <app-link
                        [appRouterLink]="isAddonView ? null : '/help/terms-of-use/'"
                        [attr.data-gtmlabel]="'Help'"
                        [href]="isAddonView ? '/help/terms-of-use/' : null"
                        [target]="'_blank'"
                        class="{{ gtmPrefix + 'help' }} my-2 mx-3"
                    >
                        Terms of Use
                    </app-link>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-------------- Reusable templates ------------>
<ng-template #partnersDetails>
    <div
        [ngClass]="{
            'mt-7 mb-2 ps-3-sm text-xs-center text-sm-start': !isAddonView,
            'mt-5': isAddonView,
            'text-md-start text-sm-center': isStudentExperience
        }"
        class="in-partnership"
    >
        In partnership with
    </div>
    <div
        [ngClass]="{
            'flex-sm-column align-items-sm-start flex-lg-row align-items-lg-center': !isStudentExperience
        }"
        class="logos d-flex flex-row align-items-center"
        id="logos-and-partners"
    >
        <span
            [attr.data-gtmlabel]="'PBS'"
            class="{{ gtmPrefix + 'logo-pbs ' + 'logo-pbs' }} pbs-img h-100 me-6 my-0"
            rel="noopener noreferrer"
        >
            <img [src]="pbsLogoWhite" alt="PBS LearningMedia" />
        </span>
        <span
            [attr.data-gtmlabel]="'WGBH'"
            class="{{ gtmPrefix + 'logo-wgbh ' + 'logo-wgbh' }} wgbh-img mx-lg-1 my-0"
        >
            <img
                alt="WGBH logo"
                src="https://d43fweuh3sg51.cloudfront.net/defaults/wgbh-logo.svg"
            />
        </span>
    </div>
</ng-template>
<ng-template #allRightsReserved>
    <div [ngClass]="{ 'px-xs-4': !isStudentExperience }" class="all-rights-reserved text-left px-2">
        <div class="text-center">
            &copy; {{ currentYear }} PBS &amp; WGBH Educational Foundation. All rights reserved.
        </div>
    </div>
</ng-template>
<div
    aria-hidden="true"
    aria-labelledby="myModalLabel"
    class="modal fade"
    id="feedbackModal"
    role="dialog"
>
    <div class="modal-dialog">
        <div class="modal-content"></div>
    </div>
</div>
